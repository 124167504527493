import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {socketStore} from "@/store/socketStore";

import profil from "@/views/profil";
import login from "@/views/login";
import dashboard from "@/views/dashboard";

import customerInfo from "@/views/customerInformation/customerInformation";
import customerInformationParentView from "@/views/customerInformationParentView";
import userInfo from "@/views/customerInformation/userInfo";

import usermanagement from "@/views/usermanagement";
import ticketcenter from "@/views/ticketcenter";

import navBar from "@/components/navigation/NavBar";
import sideBar from "@/components/navigation/SideBar";
import footer from "@/components/footer/footer";

import events from "@/views/events";
import eventOverview from "@/views/events/overview";
import eventCreate from "@/views/events/create";
import eventEdit from "@/views/events/edit";
import eventGaesteliste from "@/views/events/gaesteliste";

import ticketOverview from "@/views/ticketcenter/overview";
import ticketsales from "@/views/ticketcenter/ticketsales";
import ticketCreate from "@/views/ticketcenter/create";
import ticketEdit from "@/views/ticketcenter/edit";
import scanner from "@/views/ticketcenter/scanner";

import couponsParentView from "@/views/coupons/couponsParentView";
import couponsOverviewView from "@/views/coupons/couponsOverviewView";
import couponView from "@/views/coupons/CouponView";

const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/customerInfo',
    alias: '/kundeninfo',
    name: 'CustomerInfoParent',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: customerInformationParentView,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'CustomerInfo',
        component: customerInfo
      },
      {
        path: ':userId',
        name: 'Customer',
        component: userInfo,
        props: true
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/coupons',
    name: 'CouponsParent',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: couponsParentView,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'CouponsOverview',
        component: couponsOverviewView
      },
      {
        path: 'coupon/:couponId?',
        name: 'Coupon',
        component: couponView,
        props: true
      }
    ],
    meta: { auth: true, permission: 'couponManagement' },
  },
  {
    path: '/benutzerverwaltung',
    alias: '/usermanagement',
    name: 'Usermanagement',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: usermanagement,
      footer: footer
    },
    meta: { auth: true, permission: 'userManagement' },
  },
  {
    path: '/ticketcenter/:page?',
    name: 'Ticketcenter',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: ticketcenter,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'TicketOverview',
        component: ticketOverview,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'create',
        name: 'TicketCreate',
        component: ticketCreate,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'ticketsales',
        name: 'ticketsales',
        component: ticketsales,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'edit/:id',
        name: 'TicketEdit',
        component: ticketEdit,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'scanner',
        name: 'scanner',
        component: scanner,
        meta: { permission: 'scanTicket'}
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/events/:page?',
    name: 'Events',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: events,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'EventOverview',
        component: eventOverview
      },
      {
        path: 'create',
        name: 'EventCreate',
        component: eventCreate,
        meta: { permission: 'eventManagement'}
      },
      {
        path: 'edit/:eventId',
        name: 'EventEdit',
        component: eventEdit,
        props: true,
        meta: { permission: 'eventManagement'}
      },
      {
        path: 'gaesteliste/:eventId',
        name: 'EventGaesteliste',
        component: eventGaesteliste,
        props: true,
        meta: { permission: 'eventManagement'}
      }
    ],
    meta: { auth: true },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const socket = socketStore()

  config.menuModal = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(r => {
          socket.connect()
          config.loading = true
          if(!to.meta.permission || (auth.permissions[config.organizerId] && auth.permissions[config.organizerId][to.meta.permission])){
            next()
          }else{
            next({
              name: 'Dashboard'
            })
          }
        })
        .catch(e => {
          console.error(e)
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch( e => {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch( e =>{
          console.log(e)
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
