<template>
  <article class="border-2 py-4 mb-2 bg-slate-50 rounded px-4 ">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="mr-4 flex-shrink-0">
        <img class="w-16 w-auto rounded" :src="config.projectURL + 'assets/images/events/'+ticketItems.eventImage+'_thumb.jpg'">
      </div>
      <div class="min-w-0 flex-1">
        <h2 style="margin-bottom: -4px;" class="text-l font-normal leading-7 text-gray-400 sm:truncate sm:text-l sm:tracking-tight">
          {{ ticketItems.title }}
        </h2>
        <h2 class="text-1xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
          {{ ticketItems.ticketTitle }}
        </h2>
        <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <BanknotesIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ ticketItems.price }}€
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <TicketIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ ticketItems.soldCount }} / {{ ticketItems.quantity }}
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyEuroIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ ticketItems.soldPrice.toFixed(2) }}€
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ covertTime }}
          </div>
        </div>
        <p class="mt-1 text-sm text-gray-400 font-thin">{{ ticketItems.ticketDescription }}</p>

      </div>
    </div>
    <div class="mt-2 flex flex-row-reverse">
      <span class="">
        <button @click="$router.push({name: 'TicketEdit', params: { id: ticketItems.id }});" type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <PencilSquareIcon class="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
          Bearbeiten
        </button>
      </span>
      <span class="mr-2">
        <button @click.stop.prevent="sendTicketModal = true; sendStatus = false" type="button" class="inline-flex items-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
          <EnvelopeIcon class="-ml-0.5 mr-1.5 h-4 w-4" aria-hidden="true" />
          Ticket versenden
        </button>
      </span>
    </div>
  </article>
  <TransitionRoot as="template" :show="sendTicketModal">
    <Dialog as="div" class="relative z-10" @close="sendTicketModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="sendTicketModal = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                  <EnvelopeIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Freitickets versenden</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Wähle einen Kunden aus oder trage manuell eine E-Mail-Adresse ein, welche kostenlose Tickets von der ausgwählten Ticket-Variante erhalten soll.
                    </p>
                  </div>
                  <div>
                    <div class="relative mt-8">
                      <label for="userid" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Kunde auswählen</label>
                      <select v-model="sendUserId" name="userid" id="userid" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option :value="0">
                          Manuell
                        </option>
                        <option v-for="single in customer" :key="single.userId" :value="single.userId">
                          {{ single.lastname }}, {{ single.firstname }} ({{ single.userId }})
                        </option>
                      </select>
                    </div>
                    <div class="relative mt-4" v-if="sendUserId < 1">
                      <label for="email" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">E-Mail-Adresse</label>
                      <input type="email" v-model="sendEmail" name="email" id="email" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="max@mustermann.de" />
                    </div>
                    <div class="relative mt-4">
                      <label for="quantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Anzahl</label>
                      <input type="number" :max="ticketItems.quantity - ticketCount" v-model="sendQuantity" name="quantity" id="quantity" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl" />
                    </div>
                  </div>
                  <div class="my-3" v-if="sendError">
                    <alert type="error" title="Ups! Da ist was schiefgelaufen" message="Bitte überprüfe ob du eine korrekte E-Mail-Adresse oder Kunden ausgewählt hast und ob noch genug Tickets verfügbar sind."/>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto" :disabled="sending" :class="{disabled: sending}" @click="sendTicket">Senden</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="sendTicketModal = false">Abbrechen</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>


<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {aSyncData} from "@/modules/main";
import alert from "@/components/alerts/alert";

import {
  BriefcaseIcon,
  CalendarIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  CurrencyEuroIcon,
  LinkIcon,
  MapPinIcon,
  PencilSquareIcon,
  TicketIcon,
  BanknotesIcon,
} from '@heroicons/vue/20/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
export default {
  name: "ticketItems",
  props: ["ticketItems", "customer"],
  components: {
    BriefcaseIcon,
    CalendarIcon,
    EnvelopeIcon,
    ChevronDownIcon,
    CurrencyEuroIcon,
    LinkIcon,
    MapPinIcon,
    PencilSquareIcon,
    TicketIcon,
    BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon,
    alert,
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
      sendTicketModal: false,
      sendQuantity: 1,
      sendEmail: '',
      sendStatus: false,
      sendUserId: 0,
      sending: false,
      sendError: false
    }
  },
  computed: {
    covertTime(){
      let timestampArr = this.ticketItems.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    sendTicket(){
      this.sendStatus = false
      this.sending = true
      this.sendError = false
      aSyncData('ticketcenter/sendTicket', {
        quantity: this.sendQuantity,
        email: this.sendEmail,
        userId: this.sendUserId,
        ticketId: this.ticketItems.id
      })
          .then(r => {
            if(r.status === 250){
              this.sendEmail = ''
              this.sendQuantity = 1
              this.sendTicketModal = false
              this.sendStatus = true
              this.$emit('sendSuccess');
              this.sendUserId = 0
              this.sending = false
            }else{
              this.sendError = true
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.sending = false
          })
    }
  }
}
</script>

<style scoped>



</style>