<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <a href="/coupons" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </a>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/coupons" class="text-sm font-medium text-gray-500 hover:text-gray-700">Coupons</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Coupon {{ this.couponId ? 'bearbeiten' : 'erstellen' }}</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Ticket Informationen</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button type="button" @click="deleteCoupon()" v-if="this.couponId"  class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-purple-600 font-semibold	 text-white hover:bg-purple-700">
            Coupon löschen
          </button>
        </div>
      </div>
    </div>
    <form @submit.prevent="save()">
      <div class="mt-2 bg-white rounded shadow p-4">
        <div class="relative mt-4">
          <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Coupon-Titel</label>
          <input v-model="title" required type="text" name="title" id="title" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Coupon-Titel" />
        </div>
        <div class="relative mt-4">
          <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
          <textarea v-model="description" rows="4" name="description" id="description" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung"></textarea>
        </div>
        <div class="relative mt-4">
          <label for="extra" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Hinweise</label>
          <textarea v-model="extra" rows="1" name="extra" id="extra" class="px-2 text-sm block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Hinweise"></textarea>
        </div>
        <div class="relative mt-4">
          <div class="mt-2 flex rounded-md shadow-sm">
            <div class="relative flex flex-grow items-stretch">
              <label for="code" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Coupon-Code</label>
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <QrCodeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input v-model="code" required type="code" name="code" id="code" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Coupon-Code" />
            </div>
            <button @click="generateCode(8)" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white bg-purple-600 ring-1 ring-inset ring-gray-300 hover:bg-purple-500">
              Generieren
            </button>
          </div>
        </div>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
          <div class="sm:col-span-2">
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">
                  <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                </span>
              </div>
              <label for="validFrom" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gültig ab</label>
              <input v-model="validFrom" required type="datetime-local" name="validFrom" id="validFrom" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Gültig ab" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                  </span>
              </div>
              <label for="validUntil" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gültig bis</label>
              <input v-model="validUntil" required type="datetime-local" name="validUntil" id="validUntil" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Gültig bis" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <TicketIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                  </span>
              </div>
              <label for="limitCount" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Anzahl</label>
              <input v-model="limitCount" required type="number" name="limitCount" id="limitCount" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm">Stück</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white rounded shadow p-4">
        <RadioGroup v-model="discount.type">
          <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Rabattart auswählen</RadioGroupLabel>
          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="DiscountTypeList in DiscountTypeLists" :key="DiscountTypeList.id" :value="DiscountTypeList.id" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountTypeList.title }}</RadioGroupLabel>
                    <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ DiscountTypeList.description }}</RadioGroupDescription>
                  </span>
                </span>
                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div class="mt-4">
          <div v-if="discount.type == 'amount' || discount.type == 'percent'">
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div v-if="discount.type == 'amount'" class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <CurrencyEuroIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
                  </span>
                  </div>
                  <label for="value" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rabattbetrag</label>
                  <input v-model="discount.value" type="number" step="0.01" name="value" id="value" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                </div>
              </div>
              <div v-if="discount.type == 'percent'" class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 text-gray-500 sm:text-sm font-bold">
                    %
                  </span>
                  </div>
                  <label for="value" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rabattbetrag</label>
                  <input v-model="discount.value" type="number" name="value" id="value" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="10%" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">
                    <CurrencyEuroIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
                  </span>
                  </div>
                  <label for="minAmount" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Mindestbetrag</label>
                  <input v-model="discount.minAmount" type="number" step="0.01" name="minAmount" id="minAmount" class="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="discount.type == 'xForY'">
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <label for="x" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Anzahl</label>
                  <input v-model="discount.x" type="x" name="x" id="x" class="block w-full rounded-md border-0 py-1.5 pl-4 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <label for="y" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">für den Preis von</label>
                  <input v-model="discount.y" type="y" name="y" id="y" class="block w-full rounded-md border-0 py-1.5 pl-4 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Anzahl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white rounded shadow p-4">
        <RadioGroup v-model="events">
          <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Events</RadioGroupLabel>
          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                  </span>
                </span>
                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div class="mt-4">
          <div class="events-radio-answer">
            <div class="grid-12" v-if="events === 'included' || events === 'excluded'">
              <ul class="col-sp-6 col-tb-6 row-sp-12 event-possibilities-list">
                <h6 class="font-bold text-l">Auswahl der Events</h6>
                <li class="text-gray-500 text-sm cursor-pointer" v-for="event in items.events.filter(e => !eventsList.some(eL => eL.id === e.id))" :key="event.id" @click="addElement(eventsList, event)">
                  {{ renderTimestampForJS(event.startTime) + ' - ' +  event.title }}
                </li>
              </ul>
              <ul class="col-sp-6 col-tb-6 row-sp-12 event-selection-list">
                <h6  class="font-bold text-l">Getroffene Auswahl</h6>
                <li class="text-gray-500 text-sm cursor-pointer" v-for="event in eventsList" :key="event.id" @click="removeElement(eventsList, event)">
                  {{ renderTimestampForJS(event.startTime) + ' - ' +  event.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white rounded shadow p-4">
        <RadioGroup v-model="tickets">
          <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Tickets</RadioGroupLabel>
          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                  </span>
                </span>
                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div class="mt-4">
          <div class="events-radio-answer">
            <div class="grid-12" v-if="tickets === 'included' || tickets === 'excluded'">
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Auswahl der Tickets</h6>
                <li class="text-gray-500 text-sm cursor-pointer mb-2" v-for="ticket in items.tickets.filter(e => !ticketsList.some(eL => eL.id === e.id))" :key="ticket.id" @click="addElement(ticketsList, ticket)">
                  <b class="font-bold">{{ ticket.ticketTitle }}</b><br>
                  {{ renderTimestampForJS(ticket.startTime) + ' - ' +  ticket.title }}
                </li>
              </ul>
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Getroffene Auswahl</h6>
                <li class="text-gray-500 text-sm cursor-pointer mb-2" v-for="ticket in ticketsList" :key="ticket.id" @click="removeElement(ticketsList, ticket)">
                  <b class="font-bold">{{ ticket.ticketTitle }}</b><br>
                  {{ renderTimestampForJS(ticket.startTime) + ' - ' +  ticket.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="mt-2 bg-white rounded shadow p-4">
        <RadioGroup v-model="lounges">
          <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Lounges</RadioGroupLabel>
          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                  </span>
                </span>
                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div class="mt-4">
          <div class="events-radio-answer">
            <div class="grid-12" v-if="lounges === 'included' || lounges === 'excluded'">
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Auswahl der Lounges</h6>
                <li class="text-gray-500 text-sm cursor-pointer" v-for="lounge in items.lounges.filter(e => !loungesList.some(eL => eL.id === e.id))" :key="lounge.id" @click="addElement(loungesList, lounge)">
                  {{ lounge.title }}
                </li>
              </ul>
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Getroffene Auswahl</h6>
                <li class="text-gray-500 text-sm cursor-pointer" v-for="lounge in loungesList" :key="lounge.id" @click="removeElement(loungesList, lounge)">
                  {{ lounge.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white rounded shadow p-4">
        <RadioGroup v-model="drinks">
          <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Gültigkeit für Getränke</RadioGroupLabel>
          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="DiscountList in DiscountLists" :key="DiscountList.id" :value="DiscountList.id" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ DiscountList.title }}</RadioGroupLabel>
                  </span>
                </span>
                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div class="mt-4">
          <div class="events-radio-answer">
            <div class="grid-12" v-if="drinks === 'included' || drinks === 'excluded'">
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Auswahl der Getränke</h6>
                <li class="text-gray-500 text-sm cursor-pointer mb-2" v-for="drink in items.drinks.filter(e => !drinksList.some(eL => eL.id === e.id))" :key="drink.id" @click="addElement(drinksList, drink)">
                  <b>{{drink.menuName }}</b><br>
                  [{{drink.categoryName }}] - {{drink.drinkName}}
                </li>
              </ul>
              <ul class="col-sp-6 col-tb-6 row-sp-12">
                <h6 class="font-bold text-l">Getroffene Auswahl</h6>
                <li class="text-gray-500 text-sm cursor-pointer mb-2" v-for="drink in drinksList" :key="drink.id" @click="removeElement(drinksList, drink)">
                  <b>{{drink.menuName }}</b><br>
                  [{{drink.categoryName }}] - {{drink.drinkName}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="flex flex-row-reverse mt-4">
        <button type="submit" class="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
          {{ this.couponId ? 'Änderungen speichern' : 'Coupon erstellen' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */

import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  name: "CouponView",
  title: "Coupon editieren/erstellen",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,
    CheckCircleIcon, ClockIcon,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption
  },
  props: {
    couponId: {
      type: Number,
      required: false
    }
  },
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return{
      error: false,
      items: {},
      code: '',
      title: '',
      description: '',
      extra: '',
      validFrom: new Date().toISOString().slice(0,-8),
      validUntil: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0,-8),
      discount: {
        type: 'amount',
        minAmount: 0,
        value: 5,
        x: 3,
        y: 2
      },
      limitCount: 100,
      events: 'all',
      eventsList: [],
      tickets: 'all',
      ticketsList: [],
      lounges: 'all',
      loungesList: [],
      drinks: 'all',
      drinksList: [],
      platform: 'everywhere',
      deleteModal: false,
      DiscountTypeLists: [
        { id: 'amount', title: 'Betrag', description: 'Einen festen Betrag als Rabatt.' },
        { id: 'percent', title: 'Prozent', description: 'Einen prozentualen Betrag als Rabatt.' },
        { id: 'xForY', title: 'X für Y', description: 'Bspw. Drei zum Preis von Zwei.' },
      ],
      DiscountLists: [
        { id: 'all', title: 'Alle' },
        { id: 'included', title: 'Einschließen' },
        { id: 'excluded', title: 'Ausschließen' },
        { id: 'none', title: 'Keine' },
      ]
    }
  },
  watch: {
    events(){
      this.eventsList = []
    },
    tickets(){
      this.ticketsList = []
    },
    lounges(){
      this.loungesList = []
    },
    drinks(){
      this.drinksList = []
    },
    validFrom(){
      if(this.validFrom && this.validUntil && this.validFrom >= this.validUntil) this.validFrom = null
    },
    validUntil(){
      if(this.validFrom && this.validUntil && this.validFrom >= this.validUntil) this.validUntil = null
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      aSyncData('coupons/getData', {
        organizerId: this.config.organizerId,
        couponId: this.couponId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.items.events = r.data.events
              this.items.tickets = r.data.tickets
              //this.items.lounges = r.data.lounges
              //this.items.drinks = r.data.drinks

              if(this.couponId){
                let coupon = r.data.coupon

                this.code = coupon.code
                this.title = coupon.title
                this.description = coupon.description
                this.extra = coupon.extra
                this.validFrom = coupon.validFrom
                this.validUntil = coupon.validUntil
                this.discount = coupon.data.discount
                this.limitCount = coupon.limitCount

                this.events = coupon.data.events.type
                this.$nextTick(() => {
                  this.eventsList = this.items.events.filter(e => coupon.data.events.items.includes(e.id))
                })

                this.tickets = coupon.data.tickets.type
                this.$nextTick(() => {
                  this.ticketsList = this.items.tickets.filter(t => coupon.data.tickets.items.includes(t.id))
                })
                /*
                this.lounges = coupon.data.lounges.type
                this.$nextTick(() => {
                  this.loungesList = this.items.lounges.filter(l => coupon.data.lounges.items.includes(l.id))
                })

                this.drinks = coupon.data.drinks.type
                this.$nextTick(() => {
                  this.drinksList = this.items.drinks.filter(d => coupon.data.drinks.items.includes(d.id))
                })

                 */
              }
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    generateCode(length){
      let code = ''
      let counter = 0
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      while (counter < length) {
        //if(counter && counter%4 === 0)code += '-'
        code += chars.charAt(Math.floor(Math.random() * chars.length))
        counter++
      }
      this.code = code
    },
    addElement(list, element){
      if(!list.some(l => l.id === element.id)) list.push(element)
    },
    removeElement(list, element){
      const index = list.findIndex(l => l.id === element.id)
      if (index > -1) {
        list.splice(index, 1)
      }
    },
    deleteCoupon(){
      aSyncData('coupons/deleteCoupon', {couponId: this.couponId})
          .then(r => {
            if(r.status === 250){
              this.$router.push('/coupons?delete=true')
            }
          })
          .catch(e => {console.error(e)})
    },
    save(){
      this.error = false
      aSyncData('coupons/addOrEdit', {
        couponId: this.couponId,
        organizerId: this.config.organizerId,
        code: this.code,
        title: this.title,
        description: this.description,
        extra: this.extra,
        validFrom: this.validFrom,
        validUntil: this.validUntil,
        limitCount: this.limitCount,
        data: JSON.stringify({
          discount: this.discount,
          events: {
            type: this.events,
            items: this.eventsList.map(i => i.id)
          },
          tickets: {
            type: this.tickets,
            items: this.ticketsList.map(i => i.id)
          },
          lounges: {
            type: this.lounges,
            items: this.loungesList.map(i => i.id)
          },
          drinks: {
            type: this.drinks,
            items: this.drinksList.map(i => i.id)
          },
          plattform: {
            type: this.platform,
          }
        })
      })
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              this.$router.push('/coupons?save=true')
            }else if(r.status === 251){
              this.error = true
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
  }
}
</script>

<style scoped>

</style>