<template>
  <tr>
    <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
      {{eventStart}} Uhr - <span class="font-bold">{{ event.eventTitle }}</span>
      <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
      <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
    </td>
    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell"> {{ lastSale }} Uhr</td>
    <td class="px-3 py-4 text-sm text-gray-500 text-right">
      <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {{ (-1 * event.amount / 100).toFixed(2) }}€
      </span>
    </td>
    <td class="py-4 pl-3 text-right text-sm">
      <span v-if="typ == 'Getränke'" class="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{{typ}}</span>
      <span v-else-if="typ == 'Tickets'" class="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">{{typ}}</span>
      <span v-else class="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{{typ}}</span>
    </td>
  </tr>
</template>

<script>
import {dateToString, stringToDate} from "@/modules/main";

export default {
  name: "saleEvents",
  props: {
    event: Object
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    eventStart(){
      return dateToString(stringToDate(this.event.eventDate))
    },
    lastSale(){
      return dateToString(stringToDate(this.event.transactionDate))
    },
    typ(){
      try {
        const meta = JSON.parse(this.event.meta)
        switch (meta.type) {
          case 'drinks':
            return 'Getränke'

          case 'tickets':
            return 'Tickets'

          case 'lounges':
            return 'Lounge Reservierung'

          case 'tip':
            return 'Trinkgeld'

          default:
            return 'Sonstiges'
        }
      }catch (e)  {
        return 'Sontiges'
      }
    }
  }
}
</script>

<style scoped>

.userInformation{
  margin: 4px 0;
}

  @media (max-width: 600px){
    .userInformation{
      margin: 4px 0 12px;
    }
  }


</style>