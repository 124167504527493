<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="usermanagement">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/benutzerverwaltung" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Benutzerverwaltung</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="addUser = !addUser" type="button" class="h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-purple-600 font-semibold text-white hover:bg-purple-700">
                  Benutzer erstellen
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="grid-12">
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="bg-white shadow p-4 rounded">
                    <div>
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input v-model="searchUser" type="search" name="searchUser" id="searchUser" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Benutzern..." />
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <ul role="list" class="divide-y divide-gray-100">
                      <userItems v-for="uitem in userFiltered" :user-item="uitem" :key="uitem.id" :role-items="roleItems" @update="getUserItems(); saveMessage = true"></userItems>
                    </ul>
                    <div v-if="!userFiltered.length" class="relative pt-4">
                      <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                      </div>
                      <div class="relative flex justify-center">
                        <span class="bg-white px-2 text-sm text-gray-500">Keine passenden Nutzer gefunden</span>
                      </div>
                    </div>
                    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
                    <notification class="my-4" v-if="addInfo" type="success" title="Erfolgreich hinzugefügt" :message="addInfo"/>

                  </div>
                </div>
              </div>
          </div>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="addUser">
      <Dialog as="div" class="relative z-10" @close="addUser = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="invite()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="addUser = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                      <EnvelopeIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Nutzer zum Club <span class="text-purple-700">{{ config.getCurrentOrganizer.organizerName }}</span> einladen</DialogTitle>
                      <div>
                        <div class="relative mt-4">
                          <label for="email" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">E-Mail-Adresse</label>
                          <input required type="email" v-model="email" name="email" id="email" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="max@mustermann.de" />
                        </div>
                      </div>
                      <div>
                        <div class="relative mt-4">
                          <label for="roleId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rechtegruppe</label>
                          <select required v-model="roleId" name="roleId" id="roleId" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option v-for="(role, index) in roleItems" :key="index" :value="role.id">
                              {{ role.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto">Nutzer einladen</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="addUser = false">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import userItems from "@/components/usermanagement/user";
import notification from "@/components/notifications/notification";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon, EnvelopeIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
export default {
  name: "Benutzerverwaltung",
  title: "Benutzerverwaltung",
  components: {
    userItems,
    notification,
    MagnifyingGlassIcon, ExclamationTriangleIcon, XMarkIcon, EnvelopeIcon,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      userItems: [],
      addUser: false,
      addInfo: false,
      email: '',
      roleId: 1,
      roleItems: '',
      searchUser: '',
      saveMessage: false
    }
  },
  computed: {
    userFiltered(){
      return this.userItems.filter(user => {
        return user.organizerId == this.config.organizerId &&  (user.email.toLowerCase().includes(this.searchUser.toLowerCase()) || user.firstname.toLowerCase().includes(this.searchUser.toLowerCase()) || user.lastname.toLowerCase().includes(this.searchUser.toLowerCase()))
      }).sort((a, b) => {
        if ( a.firstname < b.firstname ){
          return -1;
        }
        if ( a.firstname > b.firstname ){
          return 1;
        }
        return 0;
      })
    }
  },
  mounted() {
    this.config.loading = false
    if(this.$route.query.invite){
      this.acceptInvite()
    }else{
      this.getUserItems()
      this.getRoleItems()
    }
  },
  methods: {
    acceptInvite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('token', this.$route.query.invite)
      this.axios.post('usermanagement/acceptInvite', fD)
          .then((response) => {
            if(response.status === 250){
              _this.addInfo = 'Du wurdest dem Club hinzugefügt'
            }else if(response.status === 281){
              _this.addInfo = 'Einladung ungültig.'
            }else if(response.status === 282){
              _this.addInfo = 'Einladung abgelaufen'
            }else if(response.status === 283){
              _this.addInfo = 'Einladung bereits angenommen'
            }
          })
          .catch(e => {console.log(e)})
          .finally(() => {
            _this.getClubItems()
            _this.getUserItems()
            _this.getRoleItems()
          })
    },
    getUserItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getUserItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.userItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    getRoleItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getRoleItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.roleItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    invite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      fD.append('roleId', this.roleId)
      fD.append('email', this.email)
      this.axios.post('usermanagement/invite', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.addUser = false
              _this.addInfo = this.email + ' wurde eingeladen.'
              _this.email = ''
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style>


</style>