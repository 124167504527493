<template>
  <div class="py-8  sm:py-0"></div>
  <footer>
    <ul>
      <li>
        <router-link to="/dashboard">
          <SquaresPlusIcon class="h-6 w-6" aria-hidden="true"/>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/events" v-if="user.checkPermission('eventManagement')">
          <CalendarDaysIcon class="h-6 w-6" aria-hidden="true"/>
          <span>Events</span>
        </router-link>
      </li>
      <li className="middle">
        <router-link to="/ticketcenter/scanner" v-if="user.checkPermission('scanTicket')">
          <QrCodeIcon class="h-12 w-12 text-white" aria-hidden="true"/>
          <span>Ticket-Scan</span>
        </router-link>
      </li>
      <li>
        <router-link to="/kundeninfo" v-if="user.checkPermission('viewSales')">
          <CurrencyEuroIcon class="h-6 w-6" aria-hidden="true"/>
          <span>Umsätze</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil">
          <img style="filter: none;" :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image bg-blue-700 circular">
          <span>Profil</span>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { Bars3Icon, XMarkIcon, QrCodeIcon, CurrencyEuroIcon, SquaresPlusIcon, CalendarDaysIcon} from '@heroicons/vue/24/outline'
export default {
  name: "Footer",
  components:{
    Bars3Icon, XMarkIcon, QrCodeIcon, CurrencyEuroIcon, SquaresPlusIcon, CalendarDaysIcon
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {},

}
</script>

<style scoped>
footer{
  display: none;
}
@media (max-width: 768px) {
  footer {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    min-height: 48px;
    padding: 8px 0 max(8px, calc(0.75 * env(safe-area-inset-bottom))) 0;
    background-color: #FFFFFF;
  }

  footer ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  footer ul li {
    width: 20%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  footer ul li a {
    display: block;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  footer ul li a img,
  footer ul li a svg{
    width: 24px;
    display: block;
    margin: 0 auto;
    filter: brightness(0.4);
  }

  footer ul li a span {
    width: 24px;
    display: inline;
    font-size: 0.8em;
    margin: 0 auto;
  }

  footer ul li.middle {
    position: relative;
    margin-top: -40px;
  }

  footer ul li.middle svg {
    background-color: #2068ff;
    border-radius: 90%;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-top: 0px;
    filter: none;
  }
}
</style>