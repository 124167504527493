<template>
  <div class="px-4" id="customerinfo">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <router-link to="/events" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </router-link>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/events" class="text-sm font-medium text-gray-500 hover:text-gray-700">Events</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Gästeliste</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Gästeliste berabeiten</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button type="button" @click="download()" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Gästeliste exportieren
          </button>
          <button type="button" @click="addGuestModal = true" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-purple-600 font-semibold	 text-white hover:bg-purple-700">
            Gast hinzufügen
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
        <div class="sm:col-span-2">
          <div class="bg-white p-4 rounded shadow">
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-2">
                <img class="w-full rounded" :src="config.projectURL + 'assets/images/events/'+eventDetails.image+'.jpg'">
              </div>
              <div class="sm:col-span-4">
                <h4 class="font-bold text-l text-gray-700">
                  {{ eventDetails.title }}
                </h4>
                <h5 class="text-sm text-gray-500">{{ eventDetails.subTitle }}</h5>
                <p class="text-gray-500 mt-2 text-sm">
                  <b class="font-bold text-gray-700">Location:</b><br>
                  {{ eventDetails.location }}
                </p>
                <p class="text-gray-500 mt-2 text-sm">
                  <b class="font-bold text-gray-700">Event Start:</b><br>
                  {{ eventStart }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:col-span-4">
          <div class="bg-white p-4 rounded shadow">
            <h4 class="font-bold text-l">Gästeliste:</h4>
            <div>
              <div class="bg-slate-50 rounded mb-2 p-4" v-for="guest in guests" :guest="guest" :key="guest.id">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-6 col-sd-8 row-sp-12">
                    <p class="text-gray-700 font-bold text-l">Gast:</p>
                    <p class="text-gray-500 text-sm">
                      <strong class="font-bold">Name:</strong>
                      {{ guest.firstname }} {{ guest.lastname }}<br>
                      <strong class="font-bold">Begleitpersonen:</strong> {{ guest.accompaniment }}
                    </p>
                  </div>
                  <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12" v-if="guest.status == 0">
                    <button class="w-full rounded bg-purple-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600" @click="changeGuestStatus(guest.id, 1)">
                      Einchecken
                    </button>
                    <button class="mt-2 w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="guestDetail = guest; editGuestModal = true">
                      Bearbeiten
                    </button>
                  </div>
                  <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12" v-else>
                    <button class="tiny fluid button bgcolor-white color-grey" @click="changeGuestStatus(guest.id, 0)">
                      <svg class="mx-auto" style="width: 16px; vertical-align: -3px;" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.9 8.1 4.5 9.5 9 14 19 4l-1.4-1.4L9 11.2 5.9 8.1ZM18 10c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.8 0 1.5.1 2.2.3L13.8.7C12.6.3 11.3 0 10 0 4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10h-2Z" fill="#a3e87e" fill-rule="evenodd" class="fill-000000"></path></svg>
                      Bereits eingecheckt<br>
                      {{covertTime(guest.timestamp)}}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="guests.length == 0">
                <p class="text-gray-500 text-sm text-center p-4">
                  Keine Gäste auf der Gästeliste
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransitionRoot as="template" :show="addGuestModal">
        <Dialog as="div" class="relative z-10" @close="addGuestModal = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <form @submit.prevent="addToGuestList()">
                  <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="addGuestModal = false">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                        <UserGroupIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                      </div>
                      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Gast zur Gästeliste hinzufügen</DialogTitle>
                        <div>
                          <div class="relative mt-4">
                            <label for="firstname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Vorname</label>
                            <input required type="text" v-model="firstname" name="firstname" id="firstname" placeholder="Vorname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                          <div class="relative mt-4">
                            <label for="lastname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Nachname</label>
                            <input required type="text" v-model="lastname" name="lastname" id="lastname" placeholder="Nachname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                          <div class="relative mt-4">
                            <label for="accompaniment" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Begleitpersonen</label>
                            <input required type="number" v-model="accompaniment" name="accompaniment" id="accompaniment" placeholder="Begleitpersonen" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button type="submit" class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto">Hinzufügen</button>
                      <button @click="addGuestModal = false;" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    </div>
                  </DialogPanel>
                </form>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <TransitionRoot as="template" :show="editGuestModal">
        <Dialog as="div" class="relative z-10" @close="editGuestModal = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <form @submit.prevent="saveGuest()">
                  <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="editGuestModal = false">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                        <UserGroupIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                      </div>
                      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Gast bearbeiten</DialogTitle>
                        <div>
                          <div class="relative mt-4">
                            <label for="guestDetailfirstname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Vorname</label>
                            <input required type="text" v-model="guestDetail.firstname" name="guestDetailfirstname" id="guestDetailfirstname" placeholder="Vorname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                          <div class="relative mt-4">
                            <label for="guestDetaillastname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Nachname</label>
                            <input required type="text" v-model="guestDetail.lastname" name="guestDetaillastname" id="guestDetaillastname" placeholder="Nachname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                          <div class="relative mt-4">
                            <label for="guestDetailaccompaniment" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Begleitpersonen</label>
                            <input required type="number" v-model="guestDetail.accompaniment" name="guestDetailaccompaniment" id="guestDetailaccompaniment" placeholder="Begleitpersonen" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                          </div>
                          <div class="relative mt-2">
                            <a class="text-gray-500 underline text-sm cursor-pointer" @click="deleteGuest(guestDetail.id)">Von der Gästeliste löschen</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button type="submit" class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto">Hinzufügen</button>
                      <button @click="editGuestModal = false;" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                    </div>
                  </DialogPanel>
                </form>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
      <notification class="my-4" v-if="addMessage" type="success" title="Gast hinzugefügt" message="Der Gast wurde zur Liste hinzugefügt."/>

    </div>
  </div>

</template>

<script>
/* eslint-disable */

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { ChevronLeftIcon, UserGroupIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon, BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon  } from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "Gästeliste",
  title: "Gästeliste",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, UserGroupIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      eventDetails: '',
      guests: '',
      eventStart: '',
      addGuestModal: false,
      editGuestModal: false,
      firstname: '',
      lastname: '',
      feedback: '',
      guestDetail: '',
      accompaniment: 0,
      guestsexport: '',
      addMessage: false,
      saveMessage: false
    }
  },
  mounted() {
    this.loadDetails()
    this.loadGuests()
    this.loadGuestsExport()
  },
  methods: {
    loadDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.eventId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getEventById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventDetails = response.data
              _this.eventStart = _this.covertTime(response.data.startTime)
            }
          })
    },
    loadGuests(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.eventId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('guestlist/getGuestListFromEvent', fD)
          .then(response => {
            if(response.status === 250){
              _this.guests = response.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    loadGuestsExport(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.eventId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('guestlist/getGuestListFromEventExport', fD)
          .then(response => {
            if(response.status === 250){
              _this.guestsexport = response.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    changeGuestStatus(id, status){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id',  id)
      fD.append('status',  status)
      this.axios.post('guestlist/changeGuestListStatus', fD)
          .then(r => {
            if(r.status === 250) {
              this.loadGuests()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    addToGuestList(){
      this.addMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.eventId)
      fD.append('sessionID', this.auth.sessionID)
      fD.append('firstname',  this.firstname)
      fD.append('lastname',  this.lastname)
      fD.append('accompaniment',  this.accompaniment)
      this.axios.post('guestlist/addToGuestList', fD)
          .then(r => {
            if(r.status === 250) {
              this.firstname = ''
              this.lastname = ''
              this.accompaniment = 0
              this.loadGuests()
              this.addMessage = true
              this.addGuestModal = false
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    saveGuest(){
      this.saveMessage = false
      const _this = this
      const fD = new FormData()
      fD.append('id', this.guestDetail.id)
      fD.append('sessionID', this.auth.sessionID)
      fD.append('firstname',  this.guestDetail.firstname)
      fD.append('lastname',  this.guestDetail.lastname)
      fD.append('accompaniment',  this.guestDetail.accompaniment)
      this.axios.post('guestlist/editGuestList', fD)
          .then(r => {
            if(r.status === 250) {
              this.guestDetail = ''
              this.editGuestModal = false
              this.loadGuests()
              this.saveMessage = true

            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteGuest(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('guestlist/deleteGuest', fD)
          .then(r => {
            if(r.status === 250) {
              this.guestDetail = ''
              this.feedback = 'successDelete'
              this.editGuestModal = false
              this.loadGuests()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    covertTime(timestamp){
      let timestampArr = timestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
    download(){
      let csv = 'Vorname,Nachname,Anzahl Begleitpersonen\r\n'
      for(let row = 0; row < this.guestsexport.length; row++){
        let keysAmount = Object.keys(this.guestsexport[row]).length
        let keysCounter = 0
        /*
        if(row === 0){
          for(let key in this.guests[row]){
            csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
          }
        }
         */
        for(let key in this.guestsexport[row]){
          csv += this.guestsexport[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }
        keysCounter = 0
      }
      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "gaesteliste-export.csv");

      link.click();
    },
  }
}
</script>

<style scoped>

</style>