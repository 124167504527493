<template>
  <li class="flex justify-between gap-x-6 py-5">
    <div class="flex gap-x-4">
      <img class="h-12 w-12 flex-none rounded-full bg-gray-300" :src="config.projectURL + 'assets/images/users/' + userItem.avatar" alt="" />
      <div class="min-w-0 flex-auto">
        <p class="text-sm font-semibold leading-6 text-gray-900">
          <a>{{ userItem.firstname }} {{ userItem.lastname }}</a>
        </p>
        <p class="mt-1 flex text-xs leading-5 text-gray-500">
          <a :href="`mailto:${userItem.email}`" class="truncate hover:underline">{{ userItem.email }}</a>
        </p>
      </div>
    </div>
    <div class="flex items-center gap-x-6">
      <div class="sm:flex sm:flex-col sm:items-end">
        <p class="text-sm leading-6 text-gray-900">{{ userItem.role }}</p>
        <div class="mt-1 flex items-center gap-x-1.5">
          <div class="relative">
            <label for="roleId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Rechtegruppe</label>
            <select v-model="roleId" id="roleId" class="text-xs px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
              <option v-for="(role, index) in roleItems" :key="index" :value="role.id">
                {{ role.title }}
              </option>
            </select>
          </div>
          <button @click="saveRole" v-if="userItem.roleId != roleId" class="rounded bg-purple-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
            Speichern
          </button>
        </div>
      </div>
      <Menu as="div" class="relative flex-none">
        <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
          <span class="sr-only">Open options</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            <MenuItem>
              <a @click="removeModal = true" class="block px-3 py-1 text-sm leading-6 text-gray-900">
                Benutzer löschen<span class="sr-only">, {{ userItem.firstname }} {{ userItem.lastname }}</span>
              </a>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </li>
  <TransitionRoot as="template" :show="removeModal">
    <Dialog as="div" class="relative z-10" @close="removeModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="scanTicketModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Benutzer löschen</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Bist du sicher, dass du {{ userItem.firstname }} {{ userItem.lastname }} aus {{ config.getCurrentOrganizer.organizerName }} entfernen möchtest?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="removeUser">Benutzer entfernen</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="removeModal = false">Abbrechen</button>
                </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "userItem",
  props: ["userItem", "roleItems"],
  emits: ['update'],
  components: {
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, EllipsisVerticalIcon
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      roleId: this.userItem.roleId,
      removeModal: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    saveRole(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.userItem.id)
      fD.append('roleId', this.roleId)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('usermanagement/changeRole', fD)
          .then((response) => {
            if(response.status === 250){
              _this.$emit('update')
            }
          })
          .catch(e => {console.log(e)})
    },
    removeUser(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.userItem.id)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('usermanagement/removeUser', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.$emit('update')
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
  .td p{
    display: inline-block;
    padding-top: 8px;
  }
  .td img{
    vertical-align: -8px;
    margin-right: 8px;
  }
  .grid-12{
    background-color: rgba(0,0,0,0.03);
    border-bottom: 4px solid #ffffff;
    border-radius: 10px;
  }
  .td select{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 8px 12px;
  }
  #editModal .vueModalInner{
    width: calc(100vw - 16px);
    max-width: 500px;
  }
  #editModal h3{
    font-size: 1.6em;
  }
  #editModal .field label{
    display: block;
  }
  #editModal .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #editModal .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
</style>