<template>
  <div class="lg:pl-72">
    <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="toggleSidebar">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex h-16 shrink-0 items-center sm:hidden mx-auto">
        <router-link to="/dashboard">
          <img class="h-8 w-auto" src="assets/images/hollywoodtramp-logo.webp" alt="Your Company" />
        </router-link>
      </div>
      <!-- Separator -->

      <div class="flex flex-1 gap-x-4 hidden sm:flex self-stretch lg:gap-x-6">
        <div class="relative flex flex-1" />
        <div class="flex items-center justify-end">
          <div class="flex justify-end">
            <router-link v-if="user.checkPermission('scanTicket')" to="/ticketcenter/scanner/" active-class="activeShortcut">
              <div class="mx-2 h-10 w-10 cursor-pointer items-center justify-center rounded-lg text-gray-400 hover:bg-gray-100 hover:text-gray-600 hidden sm:flex">
                <QrCodeIcon class="h-5 w-5" aria-hidden="true"/>
              </div>
            </router-link>
            <router-link v-if="user.checkPermission('eventManagement')" to="/events/create">
              <button class="ml-4 hidden h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-purple-600 text-white hover:bg-purple-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                </svg>
                Event erstellen
              </button>
            </router-link>
          </div>
          <div class="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 relative">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full bg-slate-300" :src="config.projectURL + 'assets/images/users/'+user.avatar" alt="">
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <router-link to="/profil" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Dein Profil</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click="auth.logout()" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Abmelden</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, QrCodeIcon, CreditCardIcon, ClipboardDocumentCheckIcon, ShoppingBagIcon} from '@heroicons/vue/24/outline'
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {mainStore} from "@/store/mainStore";

export default {
    components:{
      Disclosure,
      DisclosurePanel,
      DisclosureButton,
      Menu,
      MenuButton, MenuItem, MenuItems,
      Bars3Icon, BellIcon, XMarkIcon,
      QrCodeIcon,
      CreditCardIcon,
      ClipboardDocumentCheckIcon,
      ShoppingBagIcon
    },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const main = mainStore()


    return {auth, config, user, main}
  },
    data() {
      return {
        notificationCount: 0,
      }
    },
    methods:{
      toggleSidebar() {
        this.main.sidebarOpen = !this.main.sidebarOpen;
      },
    },
}
</script>
<style scoped>
.activeShortcut div{
  --tw-text-opacity: 1!important;
  color: rgb(147 51 234 / var(--tw-text-opacity)) !important;
  --tw-bg-opacity: 1!important;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity)) !important;
}
.activeShortcut svg{
  --tw-text-opacity: 1!important;
  color: rgb(147 51 234 / var(--tw-text-opacity)) !important;
}
</style>
