<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/profil" class="text-sm font-medium text-gray-500 hover:text-gray-700">Mein Profil</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Mein Profil</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div class="bg-white rounded p-4 shadow">
            <section>
            <!-- Settings forms -->
            <div class="divide-y">
              <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 class="text-base font-semibold leading-7 text-gray">Persönliche Informationen</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">Accountspezifische Daten</p>
                </div>

                <form @submit.prevent="edit()" class="md:col-span-2">
                  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div class="col-span-full flex items-center gap-x-8">
                      <img :src="config.projectURL + 'assets/images/users/' + user.avatar" alt="" class="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover" />
                      <div>
                        <button @click="avatarModal = true" type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          Profilbild ändern
                        </button>
                        <p class="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <div class="relative">
                        <label for="firstname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Vorname</label>
                        <input required type="text" v-model="firstname" name="firstname" id="firstname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Max" />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <div class="relative">
                        <label for="lastname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Nachname</label>
                        <input required type="text" v-model="lastname" name="lastname" id="lastname" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Mustermann" />
                      </div>
                    </div>

                    <div class="col-span-full">
                      <div class="relative">
                        <label for="email" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">E-Mail-Adresse</label>
                        <input required type="email" v-model="email" name="email" id="email" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="max@mustermann.de" />
                      </div>
                    </div>

                    <div class="col-span-full">
                      <div class="relative">
                        <label for="phone" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Telefonnummer</label>
                        <input type="tel" v-model="phone" name="phone" id="phone" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="+49 123 456 789" />
                      </div>
                    </div>

                    <div class="col-span-full">
                      <div class="mt-4 flex flex-row-reverse">
                        <button type="submit" class=" rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Änderungen speichern</button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>

              <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 class="text-base font-semibold leading-7 text-gray">Passwort ändern</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">Aktuallisiere dein Passwort für deinen Account.</p>
                </div>

                <form @submit.prevent="edit()" class="md:col-span-2">
                  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div class="col-span-full">
                      <div class="relative">
                        <label for="currentPw" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Aktuelles Passwort</label>
                        <input required type="password" v-model="confirmpw" name="currentPw" id="currentPw" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Aktuelles Passwort" />
                      </div>
                    </div>
                    <div class="col-span-full">
                      <div class="relative">
                        <label for="pw1" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Neues Passwort</label>
                        <input required type="password" v-model="pw1" name="pw1" id="pw1" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Neues Passwort" />
                      </div>
                    </div>

                    <div class="col-span-full">
                      <div class="relative">
                        <label for="pw2" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Neues Passwort wiederholen</label>
                        <input required type="password" v-model="pw2" name="pw2" id="pw2" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Neues Passwort wiederholen" />
                      </div>
                    </div>

                    <div class="col-span-full">
                      <alert class="my-4" v-if="passwordError" type="error" title="Ups! Bitte versuche es erneut." message="Das Passwort stimmt nicht mit dem aktuellen Passwort überein."/>

                      <div class="mt-4 flex flex-row-reverse">
                        <button type="submit" class=" rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Passwort ändern</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>


              <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 class="text-base font-semibold leading-7 text-gray">Account löschen</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Möchtest Du unseren Service nicht mehr nutzen? Du kannst dein Konto hier löschen. Diese Aktion kann nicht rückgängig gemacht werden. Alle Informationen im Zusammenhang mit diesem Konto werden dauerhaft gelöscht.
                  </p>
                </div>
                <form class="md:col-span-2">
                  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div class="col-span-full">
                      <div class="flex flex-row-reverse">
                        <button @click="deleteModal = true; passwordError = false" type="button" class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400">Ja, Account löschen</button>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </section>
          </div>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="avatarModal">
      <Dialog as="div" class="relative z-10" @close="avatarModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit="saveAvatar()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="avatarModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Profilbild ändern</DialogTitle>
                      <div class="mt-2">
                        <cropper-upload :init="true" :ratio="1" @cropped="c => avatarBlob = c" />
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="avatarModal = false">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="deleteModal">
      <Dialog as="div" class="relative z-10" @close="deleteModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="deleteAccount()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="deleteModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Account löschen</DialogTitle>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Möchtest du deinen Account wirklich löschen?
                        </p>
                      </div>
                      <div>
                        <div class="relative mt-4">
                          <label for="deletePassword" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Passwort zum bestätigen</label>
                          <input required type="password" v-model="deletePassword" name="deletePassword" id="deletePassword" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Passwort" />
                        </div>
                      </div>
                      <alert class="my-4" v-if="generalError" type="error" title="Ups!" message="Es ist ein Fehler aufgetreten. Bitte versuche es erneut."/>
                      <alert class="my-4" v-if="passwordError" type="error" title="Ups! Bitte versuche es erneut." message="Das Passwort stimmt nicht mit dem aktuellen Passwort überein."/>

                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Account löschen</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="deleteModal = false">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";


export default {
  name: "Profil",
  title: "Profil",
  components: {
    CropperUpload,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, notification, alert
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      phone: this.user.phone,
      pw1: '',
      pw2: '',
      confirmpw: '',
      deleteModal: false,
      status: '',
      avatarModal: false,
      avatarBlob: false,
      loadingAvatar: false,
      deletePassword: '',
      saveMessage: false,
      passwordError: false,
      generalError: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    saveAvatar(){
      this.loadingAvatar = true
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('avatar', this.avatarBlob)
      this.axios.post(this.config.projectURL + 'profil/changeAvatar', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.user.avatar = response.data.avatar
              _this.avatarModal = false;
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            _this.loadingAvatar = false
          })
    },
    edit(){
      this.saveMessage = false
      this.passwordError = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('email', this.email)
      fD.append('phone', this.phone)
      fD.append('password', this.pw1)
      fD.append('password2', this.pw2)
      fD.append('confirmpw', this.confirmpw)
      this.axios.post(this.config.projectURL + 'profil/edit', fD)
          .then(response => {
            if(response.status === 250){
              this.status = 'ok'
              const data = response.data
              this.user.email = data.email
              this.user.phone = data.phone
              this.user.firstname = data.firstname
              this.user.lastname = data.lastname
              this.saveMessage = true
            }else if(response.status === 251){
              this.status = 'pw'
            }else if(response.status === 251){
              this.status = 'error'
            }else if(response.status === 252){
              this.passwordError = true
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteAccount(){
      this.generalError = false;
      this.passwordError = false;
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('password', this.deletePassword)
      this.axios.post(this.config.projectURL + 'profil/deleteAccount', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){ this.auth.logout()}
            if(response.status === 251){ _this.generalError = true}
            if(response.status === 252){ _this.passwordError = true}
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style>

</style>