<template>
  <article class="col-sp-12 col-tb-6 col-sd-12 col-ld-6 row-sp-12 mb-4 px-4" >
    <div class="grid-12 bg-slate-50 py-2 rounded cursor-pointer" @click="$router.push({name: 'EventEdit', params: { eventId: eventSaleStats.id }});">
      <div class="col-sp-12 col-ld-4 row-sp-12">
        <img class="w-full mt-2 rounded" :src="config.projectURL + 'assets/images/events/'+eventSaleStats.image+'.jpg'">
      </div>
      <div class="col-sp-12 col-ld-8 row-sp-12 px-2">
        <span class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 mt-2 text-xs font-medium text-purple-700">{{ this.covertStartTime }}</span>
        <h4 class="font-bold text-sm">{{ eventSaleStats.title }}</h4>
        <h5 class="font-normal text-sm text-gray-500">{{ eventSaleStats.subTitle }}</h5>
        <h6 class="font-bold mt-2 text-sm">Verkaufte Tickets</h6>
        <table style="width: 100%" class="text-gray-700 text-xs">
          <tr>
            <td>Vorverkauf:</td>
            <td class="t-right">{{ tickets }}</td>
          </tr>
          <tr>
            <td>Abendkasse:</td>
            <td class="t-right">{{ abendkasse }}</td>
          </tr>
          <tr class="font-bold">
            <td>Gesamt:</td>
            <td class="t-right">{{ tickets + abendkasse }}</td>
          </tr>
          <tr class="font-bold">
            <td>Kapazität:</td>
            <td class="t-right">{{ capacity }}</td>
          </tr>
          <tr>
            <td>Gästeliste:</td>
            <td class="t-right">{{ guests }}</td>
          </tr>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "eventSaleStats",
  props: ["eventSaleStats"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
      abendkasse: 0,
      tickets: 0,
      capacity: 0,
      guests: 0
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
    this.getData()
  },
  methods: {
    getData(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.eventSaleStats.id)
      this.axios.post('/dashboard/getSalesData', fD)
          .then(r =>{
            console.log(r)
            if(r.status === 250){
              if(r.data.abendkasse != null) {
                _this.abendkasse = r.data.abendkasse
              }
              if(r.data.tickets != null) {
                _this.tickets = r.data.tickets
              }
              if(r.data.capacity != null){
              _this.capacity = r.data.capacity
              }
              if(r.data.guests != null){
                _this.guests = r.data.guests
              }

            }
          })
          .catch(e => {})
    },
    covertTime(){
      const _this = this
      let timestampArr = this.eventSaleStats.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+'';
    },
  }
}
</script>

<style scoped>

</style>