<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/events" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Events</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        </div>
      </div>
    </div>
    <div>
      <div class="relative mb-4 rounded-md shadow-sm">
        <input v-model="searchQuery" type="search" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Events..." />
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
      <div class="bg-white rounded shadow p-4">
        <div class="border-b border-gray-200 bg-white mb-4">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Aktuelle Events</h3>
        </div>
        <router-link :to="{name: 'EventCreate'}" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
          </svg>
          <button type="button" class="mt-4 inline-flex items-center rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
            <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Neues Event erstellen
          </button>
        </router-link>
        <div class="mt-4">
          <eventItemsComponent v-for="eitems in currentEvents" :event-items="eitems" :key="eitems.id" @duplicate="getEventItems();duplicateMessage = true;" />
          <div class="text-center p-4">
            <p v-if="searchQuery != '' && currentEvents.length == 0">
              Es wurden keine passenden Events mit der Suche <span class="text-purple-700">{{searchQuery}}</span> gefunden.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded shadow p-4 mt-4">
        <div class="border-b border-gray-200 bg-white mb-4">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Event Entwürfe</h3>
        </div>
        <div class="mt-4">
          <eventItemsComponent v-for="eitems in draftEvents" :event-items="eitems" :key="eitems.id" @duplicate="getEventItems();duplicateMessage = true;" />
          <div class="text-center p-4">
            <p v-if="searchQuery != '' && draftEvents.length == 0">
              Es wurden keine passenden Event-Entwürfe mit der Suche <span class="text-purple-700">{{searchQuery}}</span> gefunden.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded shadow p-4 mt-4" >
        <div class="border-b border-gray-200 bg-white mb-4">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Vergangende Events</h3>
        </div>
        <div class="mt-4">
          <eventItemsComponent v-for="eitems in pastEvents" :event-items="eitems" :key="eitems.id" @duplicate="getEventItems();duplicateMessage = true;" />
          <div class="text-center p-4">
            <p v-if="searchQuery != '' && pastEvents.length == 0">
              Es wurden keine vergangenden Events mit der Suche <span class="text-purple-700">{{searchQuery}}</span> gefunden.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <notification class="my-4" v-if="duplicateMessage" type="success" title="Duplizieren erfolgreich" message="Das Event wurde als Entwurf kopiert."/>
  <notification class="my-4" v-if="this.$route.query.feedback == 'success'" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
  <notification class="my-4" v-if="this.$route.query.feedback == 'deleteSuccess'" type="success" title="Erfolgreich gelöscht" message="Das Event wurde erfolgreich gelöscht."/>
  <notification class="my-4" v-if="this.$route.query.error == 'notfound'" type="error" title="Ups!" message="Das von dir gesuchte Event konnte nicht gefunden werden."/>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventItemsComponent from "@/components/events/event";
import {stringToDate, searchArray} from "@/modules/main";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";

export default {
  name: "EventOverview",
  title: 'Events',
  components: {
    eventItemsComponent,
    ChevronLeftIcon, ChevronRightIcon,
    notification, TicketIcon, CurrencyEuroIcon, alert, MagnifyingGlassIcon, PlusIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      searchQuery: '',
      eventItems: [],
      duplicateMessage: false,
    }
  },
  computed: {
    eventsFiltered(){
      return this.eventItems.filter(e => {
        return searchArray([e.title], this.searchQuery)
      })
    },
    currentEvents(){
      return this.eventItems.filter(e => {
        try {
          return parseInt(e.public) === 1 && stringToDate(e.endTime).getTime() >= (new Date()).getTime() && searchArray([e.title, e.subTitle, e.startTime , e.location], this.searchQuery)
        }catch (e) {
          return false
        }
      })
    },
    draftEvents(){
      return this.eventItems.filter(e => {
        try {
          return parseInt(e.public) === 0 && searchArray([e.title, e.subTitle, e.startTime, e.location], this.searchQuery)
        }catch (e) {
          return false
        }
      })
    },
    pastEvents(){
      return this.eventItems.filter(e => {
        try {
          return parseInt(e.public) === 1 && stringToDate(e.endTime).getTime() < (new Date()).getTime() && searchArray([e.title, e.subTitle, e.startTime, e.location], this.searchQuery)
        }catch (e) {
          return false
        }
      })
    }
  },
  mounted() {
    this.getEventItems()
  },
  methods: {
    getEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getEventItems', fD)
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              _this.eventItems = r.data
            }
          })
    },
  }
}
</script>

<style scoped>

</style>