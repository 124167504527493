<template>
  <TransitionRoot as="template" :show="!main.sidebarOpen">
    <Dialog as="div" class="relative z-50 lg:hidden" @close="main.sidebarOpen = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" class="-m-2.5 p-2.5" @click="toggleSidebar()">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-50 px-6 pb-4">
              <div class="flex h-16 shrink-0 items-center">
                <img class="h-8 w-auto" src="assets/images/hollywoodtramp-logo.webp" alt="Your Company" />
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <div class="flex flex-1 items-center justify-between">
                      <p class="text-l font-medium">Allgemein</p>
                    </div>
                    <ul role="list" class="-mx-2 mt-2 space-y-1">
                      <li>
                        <router-link to="/dashboard" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"></path></svg>
                          </span>
                          <span class="truncate">Dashboard</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('slotoverview')">
                        <router-link to="/slotoverview" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                          <span class="truncate">Schichten</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('notificationManagement')">
                        <router-link to="/notificationservice" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                            </svg>
                          </span>
                          <span class="truncate">Promotionservice</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('viewSales')">
                        <router-link to="/kundeninfo" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                          </span>
                          <span class="truncate">Kunden & Umsätze</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('gaderobe')">
                        <router-link to="/gaderobe" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                          </span>
                          <span class="truncate">Gaderobe</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('scanTicket')">
                        <router-link to="/ticketcenter/scanner" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                              </svg>
                          </span>
                          <span class="truncate">Ticket-Scanner</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('acceptPayment')">
                        <router-link to="/eventlypay" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                              </svg>
                          </span>
                          <span class="truncate">App-Pay</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('orderManagement')">
                        <router-link to="/orders" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                              </svg>
                          </span>
                          <span class="truncate">Bestellungen</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="flex flex-1 items-center justify-between">
                      <p class="text-l font-medium">Verwaltung</p>
                    </div>
                    <ul role="list" class="-mx-2 mt-2 space-y-1">
                      <li v-if="user.checkPermission('clubManagement')">
                        <router-link to="/clubmanager" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                        <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                          </svg>
                        </span>
                          <span class="truncate">Clubmanager</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('slotplaner')">
                        <router-link to="/slotplaner" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                          <span class="truncate">Schichtverwaltung</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('userManagement')">
                        <router-link to="/benutzerverwaltung" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                        <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                          </svg>
                        </span>
                          <span class="truncate">Benutzerverwaltung</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('eventManagement')">
                        <router-link to="/events" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                          </span>
                          <span class="truncate">Eventplaner</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('ticketManagement')">
                        <router-link to="/ticketcenter" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                            </svg>
                          </span>
                          <span class="truncate">Ticketcenter</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('barMenuManagement')">
                        <router-link to="/barmenu" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                        <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                          <svg viewBox="0 0 37.47 48.04" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M29.42 0a8 8 0 0 0-7.79 6H1.35A1.36 1.36 0 0 0 .22 8.1l14 21.82v15.42H9.35a1.35 1.35 0 0 0 0 2.7H21a1.35 1.35 0 0 0 0-2.7h-4V29.92l9.31-14.47A8 8 0 1 0 29.42 0Zm-6.67 8.72h4.64l-2.6 4H6.41l-2.59-4Zm-14.6 6.72h14.91L15.6 27Zm21.27-2a5.55 5.55 0 0 1-1.67-.26c.72-1.12 2.5-3.9 3.24-5A1.36 1.36 0 0 0 29.86 6h-5.4C26.65.65 34.74 2.18 34.77 8a5.36 5.36 0 0 1-5.35 5.4Z" data-name="Layer 1" fill="#9ca3af" class="fill-353535"></path></g></svg>
                        </span>
                          <span class="truncate">Barkarten</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('loungeManagement')">
                        <router-link to="/lounges" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                          <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M29 18.18v-2.84A3.35 3.35 0 0 0 25.66 12h-7.32A3.34 3.34 0 0 0 16 13a3.34 3.34 0 0 0-2.34-1H6.34A3.35 3.35 0 0 0 3 15.34v2.84A3 3 0 0 0 1 21v7a1 1 0 0 0 1 1h1v1a1 1 0 0 0 2 0v-1h22v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 1-1v-7a3 3 0 0 0-2-2.82ZM18.34 14h7.32A1.34 1.34 0 0 1 27 15.34v2.83h-.07a2.86 2.86 0 0 0-.43.21l-.15.08a2.68 2.68 0 0 0-.49.4l-.09.12a2.88 2.88 0 0 0-.3.39l-.11.19a2.2 2.2 0 0 0-.17.39.61.61 0 0 0-.05.12h-.08a2.39 2.39 0 0 0-.66-.1H17v-4.63A1.34 1.34 0 0 1 18.34 14ZM7 22.6a.6.6 0 0 1 .6-.6H15v1H7Zm10-.6h7.4a.6.6 0 0 1 .6.6v.4h-8ZM5 15.34A1.34 1.34 0 0 1 6.34 14h7.32A1.34 1.34 0 0 1 15 15.34V20H7.6a2.84 2.84 0 0 0-.66.09h-.07v-.11a3.89 3.89 0 0 0-.19-.42l-.09-.17a2.93 2.93 0 0 0-.41-.52 2.74 2.74 0 0 0-.5-.4s-.12-.06-.17-.1a3.49 3.49 0 0 0-.39-.19h-.07ZM3 21a1 1 0 0 1 1-1 1 1 0 0 1 .71.31A.93.93 0 0 1 5 21v6H3Zm4 4h18v2H7Zm22 2h-2v-6a1 1 0 0 1 1-1 1 1 0 0 1 .71.31.93.93 0 0 1 .29.69Z" data-name="Layer 2" fill="#9ca3af" class="fill-000000"></path></svg>
                          </span>
                          <span class="truncate">Lounges</span>
                        </router-link>
                      </li>
                      <li v-if="user.checkPermission('couponManagement')">
                        <router-link to="/coupons" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                        <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M456 128.8H175.492a7.998 7.998 0 0 0-8 8 11.867 11.867 0 0 1-23.734 0 7.998 7.998 0 0 0-8-8H56a7.998 7.998 0 0 0-8 8v238.4a7.998 7.998 0 0 0 8 8h79.758a7.998 7.998 0 0 0 8-8 11.867 11.867 0 1 1 23.734 0 7.998 7.998 0 0 0 8 8H456a7.998 7.998 0 0 0 8-8V136.8a7.998 7.998 0 0 0-8-8Zm-8 238.4H182.32a27.869 27.869 0 0 0-53.39 0H64V144.8h64.93a27.869 27.869 0 0 0 53.39 0H448Z" fill="#9ca3af" class="fill-000000"></path><path d="M271.906 249.613a28.804 28.804 0 0 0 20.375-49.172h-.004c-10.89-10.89-29.87-10.89-40.746 0a28.797 28.797 0 0 0 20.375 49.172Zm-9.058-37.86a12.793 12.793 0 0 1 18.113 0h.004a12.816 12.816 0 1 1-18.117 0ZM362.652 270.816c-10.882-10.89-29.863-10.89-40.746 0a28.813 28.813 0 1 0 40.75 0Zm-11.312 29.43a13.115 13.115 0 0 1-18.121 0 12.815 12.815 0 1 1 18.12 0ZM251.035 312.059a7.997 7.997 0 0 0 11.313 0l100.8-100.805a8 8 0 0 0-11.312-11.313l-100.8 100.805a7.997 7.997 0 0 0 0 11.313ZM155.625 182.02a7.998 7.998 0 0 0-8 8v18.445a8 8 0 0 0 16 0V190.02a7.998 7.998 0 0 0-8-8ZM155.625 238.777a7.998 7.998 0 0 0-8 8v18.446a8 8 0 0 0 16 0v-18.446a7.998 7.998 0 0 0-8-8ZM155.625 295.535a7.998 7.998 0 0 0-8 8v18.453a8 8 0 0 0 16 0v-18.453a7.998 7.998 0 0 0-8-8Z" fill="#9ca3af" class="fill-000000"></path></svg>
                        </span>
                          <span class="truncate">Coupons</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <a @click="auth.logout()" class="cursor-pointer block py-2 text-sm text-gray-700">Abmelden</a>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-8">
      <div class="flex h-16 shrink-0 items-center">
        <a href="/dashboard" class="mr-1 text-indigo-300">
          <img class="h-10 w-auto" src="assets/images/hollywoodtramp-logo.webp" alt="Your Company" />
        </a>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <div class="flex flex-1 items-center justify-between">
              <p class="text-l font-medium">Allgemein</p>
            </div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li>
                <router-link to="/dashboard" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"></path></svg>
                  </span>
                  <span class="truncate">Dashboard</span>
                </router-link>
              </li>
              <li v-if="user.checkPermission('viewSales')">
                <router-link to="/kundeninfo" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                    </svg>
                  </span>
                  <span class="truncate">Kunden & Umsätze</span>
                </router-link>
              </li>
              <li v-if="user.checkPermission('scanTicket')">
                <router-link to="/ticketcenter/scanner" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                      </svg>
                  </span>
                  <span class="truncate">Ticket-Scanner</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <div class="flex flex-1 items-center justify-between">
              <p class="text-l font-medium">Verwaltung</p>
            </div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li v-if="user.checkPermission('userManagement')">
                <router-link to="/benutzerverwaltung" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>
                  </span>
                  <span class="truncate">Benutzerverwaltung</span>
                </router-link>
              </li>
              <li v-if="user.checkPermission('eventManagement')">
                <router-link to="/events" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                  </span>
                  <span class="truncate">Eventplaner</span>
                </router-link>
              </li>
              <li v-if="user.checkPermission('ticketManagement')">
                <router-link to="/ticketcenter" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                    </svg>
                  </span>
                  <span class="truncate">Ticketcenter</span>
                </router-link>
              </li>
              <li v-if="user.checkPermission('couponManagement')">
                <router-link to="/coupons" active-class="active"  class="text-gray-400 hover:bg-gray-100 hover:text-gray-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-sm font-semibold text-grey">
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M456 128.8H175.492a7.998 7.998 0 0 0-8 8 11.867 11.867 0 0 1-23.734 0 7.998 7.998 0 0 0-8-8H56a7.998 7.998 0 0 0-8 8v238.4a7.998 7.998 0 0 0 8 8h79.758a7.998 7.998 0 0 0 8-8 11.867 11.867 0 1 1 23.734 0 7.998 7.998 0 0 0 8 8H456a7.998 7.998 0 0 0 8-8V136.8a7.998 7.998 0 0 0-8-8Zm-8 238.4H182.32a27.869 27.869 0 0 0-53.39 0H64V144.8h64.93a27.869 27.869 0 0 0 53.39 0H448Z" fill="#9ca3af" class="fill-000000"></path><path d="M271.906 249.613a28.804 28.804 0 0 0 20.375-49.172h-.004c-10.89-10.89-29.87-10.89-40.746 0a28.797 28.797 0 0 0 20.375 49.172Zm-9.058-37.86a12.793 12.793 0 0 1 18.113 0h.004a12.816 12.816 0 1 1-18.117 0ZM362.652 270.816c-10.882-10.89-29.863-10.89-40.746 0a28.813 28.813 0 1 0 40.75 0Zm-11.312 29.43a13.115 13.115 0 0 1-18.121 0 12.815 12.815 0 1 1 18.12 0ZM251.035 312.059a7.997 7.997 0 0 0 11.313 0l100.8-100.805a8 8 0 0 0-11.312-11.313l-100.8 100.805a7.997 7.997 0 0 0 0 11.313ZM155.625 182.02a7.998 7.998 0 0 0-8 8v18.445a8 8 0 0 0 16 0V190.02a7.998 7.998 0 0 0-8-8ZM155.625 238.777a7.998 7.998 0 0 0-8 8v18.446a8 8 0 0 0 16 0v-18.446a7.998 7.998 0 0 0-8-8ZM155.625 295.535a7.998 7.998 0 0 0-8 8v18.453a8 8 0 0 0 16 0v-18.453a7.998 7.998 0 0 0-8-8Z" fill="#9ca3af" class="fill-000000"></path></svg>
                  </span>
                  <span class="truncate">Coupons</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>

</template>

<script >
/* eslint-disable */
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import Notification from "@/components/notifications/notification.vue";
import alert from "@/components/alerts/alert.vue";
import feedbackModal from "@/components/modals/feedbackModal.vue";
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {mainStore} from "@/store/mainStore";


export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const main = mainStore()

    return {auth, config, user, main}
  },
  methods: {
    toggleSidebar() {
      this.main.sidebarOpen = !this.main.sidebarOpen;
    },
  },
  components: {
    Notification,
    alert,
    feedbackModal,
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ChevronDownIcon,
    MagnifyingGlassIcon

  },
  data(){
    return{
      infoErgebnisse: false,
      infoSeiten: false,
    }
  },
};
</script>
<style scoped>
.active{
  --tw-border-opacity: 1!important;
  border-color: rgb(99 102 241 / var(--tw-border-opacity))!important;
  --tw-text-opacity: 1!important;
  color: rgb(55 65 81 / var(--tw-text-opacity))!important;
  --tw-bg-opacity: 1!important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
</style>
