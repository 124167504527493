<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/ticketcenter" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Ticketcenter</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <router-link to="/ticketcenter/create"  class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Ticketvariante erstellen
          </router-link>
          <router-link :to="{name: 'ticketsales'}"  class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-purple-600 font-semibold	 text-white hover:bg-purple-700">
            Alle Verkäufe
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <div class="grid-12">
        <div class="col-sp-12 col-tb-8 row-sp-12 pad-4">
          <div class="relative mb-4 rounded-md shadow-sm">
            <input v-model="searchQuery" type="search" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Tickets..." />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
          <notification class="my-4" v-if="sendTicket" type="success" title="Juhu!" message="Die Tickets wurden erfolgreich versendet"/>
          <div class="bg-white rounded p-4 shadow" id="ticketResults">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12">
                <h3 class="font-bold text-xl">Ticket-Varianten</h3>
              </div>
            </div>
            <div>
              <TicketLoader v-if="loading"/>
              <ticketItems @sendSuccess="sendTicket = true" v-for="titems in filteredTickets" :ticket-items="titems" :customer="customer" :key="titems.id" />
              <div v-if="!filteredTickets.length && !loading" class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center">
                  <span class="bg-white px-2 text-sm text-gray-500">Keine passenden Ticketvarianten gefunden</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
          <div>
            <dl class="grid gap-5">
              <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                <dt>
                  <div class="absolute rounded-md bg-indigo-500 p-3">
                    <TicketIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p class="ml-16 truncate text-sm font-medium text-gray-500">Tickets verkauft:</p>
                </dt>
                <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p class="text-2xl font-semibold text-gray-900">{{ soldCount }}</p>
                  <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div class="text-sm">
                      <router-link to="/ticketcenter/ticketsales" class="font-medium text-indigo-600 hover:text-indigo-500">Alle Verkäufe anzeigen</router-link>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
          <div>
            <dl class="mt-5 grid gap-5">
              <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                <dt>
                  <div class="absolute rounded-md bg-indigo-500 p-3">
                    <CurrencyEuroIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p class="ml-16 truncate text-sm font-medium text-gray-500">Gesamt-Umsatz:</p>
                </dt>
                <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p class="text-2xl font-semibold text-gray-900">{{ soldPrice.toFixed(2) }}€</p>
                  <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div class="text-sm">
                      <router-link to="/kundeninfo" class="font-medium text-indigo-600 hover:text-indigo-500">Umsätze anzeigen</router-link>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/ticketcenter/ticket";
import {aSyncData} from "@/modules/main";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import TicketLoader from '@/components/loader/ticketLoader'

export default {
  name: "Ticketcenter",
  title: "Ticketcenter",
  components: {
    ticketItems: ticketItems,
    ChevronLeftIcon, ChevronRightIcon,
    notification, TicketIcon, CurrencyEuroIcon, MagnifyingGlassIcon,
    TicketLoader
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketItems: [],
      customer: [],
      searchQuery: '',
      sendTicket: false,
      loading: true,
    }
  },
  computed: {
    filteredTickets(){
      const _this = this
      return this.ticketItems.filter(i => {
        return i.title.toLowerCase().includes(_this.searchQuery.toLowerCase()) || i.ticketTitle.toLowerCase().includes(_this.searchQuery.toLowerCase())
      })
    },
    soldCount(){
      return this.ticketItems.reduce( (a, b) =>{
        return a + b.soldCount
      }, 0)
    },
    soldPrice(){
      return this.ticketItems.reduce( (a, b) =>{
        return a + b.soldPrice
      }, 0)
    }
  },
  mounted() {
    this.config.loading = false
    this.getTicketItems()
  },
  methods: {
    getTicketItems(){
      aSyncData('ticketcenter/getTicketItems', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              console.log(r.data)
              this.ticketItems = r.data.tickets
              this.customer = r.data.customer
              this.loading = false
            }
          })
    }
  }
}
</script>

<style scoped>

</style>