<template>
  <div class="px-4" id="ticketEdit">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <router-link to="ticketcenter" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </router-link>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/ticketcenter" class="text-sm font-medium text-gray-500 hover:text-gray-700">Ticketcenter</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Ticket erstellen</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Neue Ticket-Variante erstellen</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">

        </div>
      </div>
    </div>
    <div>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 p-1">
          <form @submit.prevent="createTicket()">
            <div class="bg-white rounded p-6 shadow">
            <RadioGroup v-model="ticketType">
              <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Ticketart auswählen</RadioGroupLabel>
              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <RadioGroupOption as="template" v-for="TicketTypeList in TicketTypeLists" :key="TicketTypeList.id" :value="TicketTypeList.id" v-slot="{ checked, active }">
                  <div :class="[checked ? 'border-transparent' : 'border-grey-600', active ? 'border-indigo-600 ring-2 ring-indigo-600' : '', 'relative flex cursor-pointer rounded-lg border bg-slate-50 p-4 shadow-sm focus:outline-none']">
                    <span class="flex flex-1">
                      <span class="flex flex-col">
                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ TicketTypeList.title }}</RadioGroupLabel>
                        <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ TicketTypeList.description }}</RadioGroupDescription>
                      </span>
                    </span>
                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div class="relative mt-4 mb-4">
              <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
              <select v-model="eventId" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                <eventOptionItems v-for="eitems in eventOptionItems" :event-option-items="eitems" :key="eitems.id" />
              </select>
            </div>
            <div class="relative mt-4">
              <label for="ticketTitle" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticketname</label>
              <input v-model="ticketTitle" required maxlength="20" type="text" name="ticketTitle" id="ticketTitle" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Ticketname (max. 20 Zeichen)" />
            </div>
            <div class="relative mt-4">
              <label for="ticketDescription" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung & Informationen</label>
              <textarea v-model="ticketDescription" rows="4" name="ticketDescription" id="ticketDescription" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung & Informationen"></textarea>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">
                      <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                    </span>
                  </div>
                  <label for="sellStart" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsstart</label>
                  <input v-model="sellStart" required type="datetime-local" name="sellStart" id="sellStart" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsstart" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">
                        <ClockIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                      </span>
                  </div>
                  <label for="sellEnd" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verkaufsende</label>
                  <input v-model="sellEnd" required type="datetime-local" name="sellEnd" id="sellEnd" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Verkaufsende" />
                </div>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2">
                  <label for="feeType" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ticket Gebühr</label>
                  <select v-model="feeType" id="feeType" class="px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option value="none">
                      Keine
                    </option>
                    <option value="ticket">
                      Pro Ticket
                    </option>
                    <option value="order">
                      Pro Bestellung
                    </option>
                  </select>
                </div>
              </div>
              <div class="sm:col-span-3" v-if="feeType != 'none'">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">€</span>
                  </div>
                  <label for="fee" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Gebühr (inkl. MwSt.)</label>
                  <input v-model="fee" type="number" step="0.01" name="fee" id="fee" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="fee-currency" />
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span class="text-gray-500 sm:text-sm" id="fee-currency">EUR</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">€</span>
                  </div>
                  <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Brutto-Endpreis (inkl. MwSt.)</label>
                  <input v-model="price" required type="number" step="0.01" name="price" id="price" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-3">
                <div class="relative mt-2 rounded-md shadow-sm">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span class="text-gray-500 sm:text-sm">
                      <TicketIcon class="h-6 w-3 text-gray-500" aria-hidden="true" />
                    </span>
                  </div>
                  <label for="quantity" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Verfügbare Tickets</label>
                  <input v-model="quantity" required type="text" name="quantity" id="quantity" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span class="text-gray-500 sm:text-sm">Stück</span>
                  </div>
                </div>
              </div>
            </div>
            <fieldset>
              <div class="relative flex items-start px-2 mb-8">
                <div class="flex h-6 items-center">
                  <input  v-model="personal" id="personal" aria-describedby="comments-description" name="personal" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label for="personal" class="font-medium text-gray-900">Personengebunden</label>
                  <p id="comments-description" class="text-gray-500">Tickets sind personengebunden und können nicht weitergegeben werden</p>
                </div>
              </div>
            </fieldset>
            <div class="pb-4">
              <alert class="my-4" v-if="ticketError" type="error" title="Ups! Es ist etwas schief gelaufen." :message="ticketError"/>
            </div>
            <div class="flex flex-row-reverse">
              <button type="submit" class="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                Ticket erstellen
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventOptionItems from "@/components/ticketcenter/eventOptionItems";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon } from '@heroicons/vue/20/solid';
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default {
  name: "ticketCreate",
  title: 'Create Ticket',
  components: {
    eventOptionItems: eventOptionItems,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      eventOptionItems: [],
      ticketTitle: '',
      ticketDescription: '',
      price: 0,
      quantity: 0,
      eventId: null,
      personal: 0,
      sellStart: null,
      sellEnd: null,
      feeType: 'none',
      fee: 0,
      ticketError: '',
      ticketType: 1,
      TicketTypeLists: [
        { id: 1, title: 'Standard-Ticket', description: 'Normales Ticket' },
        { id: 2, title: 'Multi-Ticket', description: 'Ticket für 2 Tage / 2 Scans gültig.' },
      ]
    }
  },
  watch: {
    eventId(){
      this.eventOptionItems.forEach(e => {
        if(e.id === this.eventId){
          this.sellEnd = e.endTime.replace(' ', 'T')
        }
      })
    }
  },
  mounted() {
    this.config.loading = false
    let date = new Date()
    this.sellStart = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate().length === 1 ? date.getDate() : ('0' + date.getDate())) + 'T' + date.getHours() + ':' + date.getMinutes()

    this.getEventOptionItems()
  },
  methods: {
    createTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('eventId', this.eventId)
      fD.append('personal', this.personal)
      fD.append('sellStart', this.sellStart)
      fD.append('sellEnd', this.sellEnd)
      fD.append('feeType', this.feeType)
      fD.append('fee', this.fee)
      fD.append('ticketType', this.ticketType)
      console.log('tst')
      if (this.validateTicketCreation()) {
        console.log('Test2')
        this.axios.post('ticketcenter/createTicket', fD)
            .then(r => {
              console.log(r)
              if (r.status === 250) {
                _this.$router.push('Ticketcenter')
              }
            })
      }
    },
    validateTicketCreation(){
      this.ticketError = '';
      if(!this.ticketTitle.length >= 1 && !this.ticketTitle.length <= 20){
        this.ticketError = 'Bitte gebe einen Ticket-Titel ein, der maximal 20 Zeichen beträgt.';
      }else if(this.sellStart == null || this.sellEnd == null){
        this.ticketError = 'Bitte gebe einen Verkaufsstart und Ende an.';
      }else if(!(this.quantity >= 0) || this.price === ''){
        this.ticketError = 'Bitte gebe einen Ticketpreis und Menge an.';
      }else{
        this.ticketError = '';
        return true;
      }
    },
    getEventOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/getEventOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventOptionItems = response.data

              if(_this.eventOptionItems.length){
                _this.eventId = _this.eventOptionItems[0].id

                _this.eventOptionItems.forEach(e => {
                  if(e.id === _this.eventId){
                    _this.sellEnd = e.endTime.replace(' ', 'T')
                  }
                })
              }
            }
          })
    },
  }
}
</script>

<style scoped>

</style>